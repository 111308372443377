<template>
  <div class="email">
    <div class="email__top">
      <div class="email-input">
        <form-input-text
          v-model:value="authForm.email"
          label=""
          :placeholder="getContent(currentLocaleData, defaultLocaleData, 'enterEmailAddress')"
          name="email"
          autocomplete="off"
          is-required
          :class="'auth-field'"
        />
      </div>

      <div class="email-input">
        <form-input-password
          v-model:value="authForm.password"
          name="newPassword"
          :placeholder="getContent(currentLocaleData, defaultLocaleData, 'enterPassword')"
          is-required
          label=""
          :class="'auth-field'"
          @submit="handleSubmit"
        />
      </div>

      <div v-if="type === 'login'" class="forgot-password" @click="openForgotPassDialog">
        {{ getContent(currentLocaleData, defaultLocaleData, 'forgotPassword') }}
      </div>

      <div v-else>
        <div class="email__bonus-label">
          {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.bonusCode.label') }}
        </div>

        <form-input-text
          v-model:value="authForm.bonusCode"
          label=""
          :placeholder="getContent(fieldsSettings, defaultLocaleFieldsSettings, 'fieldsControls.bonusCode.placeholder')"
          name="bonus"
          autocomplete="off"
          :class="'auth-field'"
        />
      </div>
    </div>

    <div class="email-bonus--bottom">
      <form-input-checkbox v-if="type === 'registration'" class="email-tc" v-model="authForm.agreeTC">
        <span class="email-tc-text">
          {{ getContent(currentLocaleData, defaultLocaleData, 'consent') }}

          <NuxtLink :to="to" target="_blank">
            {{ getContent(currentLocaleData, defaultLocaleData, 'termsCondition') }}
          </NuxtLink>
        </span>
      </form-input-checkbox>

      <div class="email-buttons">
        <button-base
          type="primary"
          size="md"
          class="email-btn"
          :is-disabled="formHasError || isLockedAsyncButton"
          :isProcessing="isLockedAsyncButton"
          @click="handleSubmit"
        >
          {{ buttonSubmitText[type] }}
        </button-base>

        <div class="email-auth-login">
          <div
            v-if="botID?.toString().length"
            class="email-auth-btn email-auth-btn-secondary email-auth-social"
            @click="triggerTelegramLogin('auth')"
          >
            <atomic-spinner :is-shown="isTelegramLoginInProgress" />
            <atomic-icon id="telegram" class="email-auth-icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import queryString from 'query-string';

  import { ModalName } from '@skeleton/consts/modals';
  import type { IModalsContent } from '~/types';

  const props = defineProps<{
    type: 'registration' | 'login';
    countryOrigin: string;
    currentLocaleData: Maybe<IModalsContent['authorization']>;
    defaultLocaleData: Maybe<IModalsContent['authorization']>;
  }>();

  const { getContent, localizePath } = useProjectMethods();

  const profileStore = useProfileStore();
  const { logIn } = profileStore;

  const { alertsData, defaultLocaleAlertsData, fieldsSettings, defaultLocaleFieldsSettings } =
    storeToRefs(useGlobalStore());

  const { showAlert } = useLayoutStore();
  const { closeModalSync, openModal } = useModalStore();
  const { isTelegramLoginInProgress, triggerTelegramLogin, botID } = useTelegramLogin();

  const { requireEmailVerification } = storeToRefs(useAuthorization());

  const { referralId } = storeToRefs(profileStore);

  const buttonSubmitText = computed(() => ({
    registration: getContent(props.currentLocaleData, props.defaultLocaleData, 'register'),
    login: getContent(props.currentLocaleData, props.defaultLocaleData, 'login'),
  }));

  const to = computed(() => localizePath('/static/terms-and-conditions'));

  const formHasError = ref(false);
  const isLockedAsyncButton = ref(false);

  const authForm = reactive({
    agreeTC: false,
    email: '',
    password: '',
    bonusCode: referralId.value,
  });

  watchEffect(() => {
    if (props.type === 'registration') {
      formHasError.value = !authForm.agreeTC || !authForm.email.length || !authForm.password.length;
    } else {
      formHasError.value = !authForm.email.length || !authForm.password.length;
    }
  });

  const openForgotPassDialog = (): void => {
    closeSignInDialog();
    openModal(ModalName.FORGOT_PASS);
  };

  const closeSignInDialog = (): void => {
    closeModalSync(ModalName.SIGN_IN);
    closeModalSync(ModalName.SIGN_UP);
  };

  const handleSubmit = () => {
    if (props.type === 'login') {
      login();
    } else {
      register();
    }
  };

  const login = async () => {
    try {
      isLockedAsyncButton.value = true;
      await logIn({ login: authForm.email, password: authForm.password });
      closeSignInDialog();
    } catch (error: any) {
      const errorResponse = [401, 422, 403];
      if (errorResponse.includes(error.response?.status)) {
        showAlert(alertsData.value?.profile?.loginError || defaultLocaleAlertsData.value?.profile?.loginError);
      }
    } finally {
      isLockedAsyncButton.value = false;
    }
  };

  const register = async () => {
    try {
      isLockedAsyncButton.value = true;
      const { registration } = useProfileStore();

      await registration(
        {
          email: authForm.email,
          password: authForm.password,
          country: props.countryOrigin,
          refId: authForm.bonusCode,
          bonusCode: authForm.bonusCode,
        },
        requireEmailVerification.value
      );

      closeSignInDialog();
      openModal(ModalName.CONFIRM_EMAIL);
    } catch (error: any) {
      const errorResponse = [401, 422, 403];

      if (errorResponse.includes(error.response?.status)) {
        showAlert(
          alertsData.value?.profile?.registrationValidationError ||
            defaultLocaleAlertsData.value?.profile?.registrationValidationError
        );
      }

      if (error.data?.error?.code === 10025) {
        closeModalSync(ModalName.SIGN_IN);
        closeModalSync(ModalName.SIGN_UP);
        openModal(ModalName.GEO_POPUP);
      }
    } finally {
      isLockedAsyncButton.value = false;
    }
  };

  const { $auth0 } = useNuxtApp();

  const authGoogle = () => {
    if (!$auth0) return;

    useEvent('analyticsEvent', {
      event: 'registrationChangeType',
      regType: 'social',
    });

    const { query, path } = useRoute();
    const formedQuery = queryString.stringify({
      ...query,
      'sign-in': undefined,
      stag: undefined,
    });

    $auth0.loginWithRedirect({
      appState: {
        type: 'login',
        targetUrl: formedQuery ? `${path}?${formedQuery}` : path,
      },
      authorizationParams: {
        connection: 'google-oauth2',
      },
    });
  };
</script>

<style src="~/assets/styles/components/authorization/email.scss" lang="scss" />
